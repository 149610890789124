<!-- eslint-disable vue/no-mutating-props -->
<template>
  <validation-observer ref="refFormObserver">
    <b-card
      body-class="p-50"
      class="mb-50 mb-md-1 border-info-2"
    >
      <b-row
        class="mx-0 mb-50"
        no-gutters
      >
        <b-col
          cols="12"
          md="7"
          class="bg-blue-gradient d-flex mb-25 mb-md-0"
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-alert
              show
              variant="primary"
              class="p-75 p-md-1 mr-25 mr-md-75 mb-0"
              style="width: fit-content"
            >
              <feather-icon
                icon="UsersIcon"
                :size="isMobileView ? '20' : '30'"
                class="text-body"
              />
            </b-alert>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <div :class="`text-airline fw-700 ${isMobileView ? 'font-medium-4' : 'font-medium-3'}`">
              {{ $t('flight.passengerInfo') }}
              <feather-icon
                v-if="isMobileView"
                id="header-tooltip-mobile-pax"
                icon="InfoIcon"
                size="16"
                class="text-info cursor-pointer"
              />
            </div>
            <b-tooltip
              v-if="isMobileView"
              target="header-tooltip-mobile-pax"
              triggers="hover focus"
              boundary="window"
              variant="info"
            >
              <small> {{ $t('flight.passengerInfoNote') }}</small>
            </b-tooltip>

            <span v-if="!isMobileView">
              {{ $t('flight.passengerInfoNote') }}
            </span>
          </div>
        </b-col>

        <b-col
          sm="12"
          md="5"
          class="d-flex align-items-center justify-content-center justify-content-md-end w-100"
        >
          <b-button
            v-if="enableQuickName && isDevelopment && !isHideFunction"
            id="id-icon-quick-input-multiple-pax"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            class="rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2"
            variant="danger"
            @click="fakePaxInfo"
          >
            <feather-icon
              class="text-white mr-50 d-none d-md-inline"
              icon="ZapIcon"
              size="14"
            />
            <span class="text-nowrap">Nhập tên siêu nhanh</span>
          </b-button>
          <b-button
            v-if="suggestVisible && !isHideFunction"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2 pulse-button"
            @click="() => $bvModal.show('suggest-shop-modal')"
          >
            <div class="d-flex-center gap-1">
              <feather-icon
                class="mr-50 d-none d-md-inline"
                icon="SearchIcon"
              />
              <span class="text-nowrap">
                {{ $t('suggestShopVJ') }}

              </span>
            </div>
          </b-button>
          <!-- <b-button
            v-if="hasDiscountBSVTripVN1A(selectedTrip)"
            id="hasDiscountBSVTripVN1A"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-50 px-md-1 pulse-button"
          >
            <div class="d-flex-center gap-1">
              <feather-icon
                class="mr-25 d-none d-md-inline"
                icon="DollarSignIcon"
              />
              <span class="text-nowrap fw-800">
                Giảm 5%
              </span>
            </div>

            <b-tooltip
              target="hasDiscountBSVTripVN1A"
              triggers="hover focus"
              placement="top"
              boundary="window"
              variant="danger"
              title="Nhập mã BSV có hạng thẻ từ Silver trở lên, sau đó book ra code và gửi booker xuất để được giảm 5% ."
            >
              <span class="text-white py-25 font-small-4 fw-700">
                Nhập mã BSV có hạng thẻ từ Silver trở lên, sau đó book ra code và gửi booker xuất để được giảm 5% .
              </span>
            </b-tooltip>
          </b-button> -->

          <b-button
            v-if="enableQuickName && !isHideFunction"
            id="id-icon-quick-input-multiple-pax"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            class="rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2"
            variant="warning"
            @click="handleShowModalQuickInput"
          >
            <feather-icon
              class="text-white mr-50 d-none d-md-inline"
              icon="ZapIcon"
              size="14"
            />
            <span class="text-nowrap">{{ isMobileView ? 'Nhập nhanh' : $t('flight.quickInputPax.title') }}</span>
          </b-button>

          <div
            v-if="!isEmpty(selectedTrip) && !selectedTrip.every(trip => SOURCE_DISABLE_ADD_MORE_PAXS.includes(trip.source)) && !isHideFunction"
            class="mr-50"
          >
            <BookingAddMorePassenger
              :pax-lists="passengerDataToAdd"
              @changeQuantityPax="(val) => $emit('multipleQuantityPaxChange', val)"
            />
          </div>
        </b-col>
      </b-row>

      <b-row
        v-if="selectedTrip.some(trip => ['VJ'].includes(trip.source))"
        class="mx-0"
      >
        <b-alert
          show
          variant="danger"
          class="p-50 my-50"
          style="width: fit-content"
        >
          <span class="fw-700">
            Lưu ý: "Theo yêu cầu của VietjetAir tất cả hành khách phải nhập ngày sinh. Trong trường hợp Quý AG để trống chúng tôi sẽ cung cấp dữ liệu ngẫu nhiên. {{ mamaShortName }} không chịu trách nhiệm nếu quý AG không nhập đầy đủ thông tin !"
          </span>
        </b-alert>
      </b-row>

      <!-- SECTION - Passenger (Reservations Form) -->
      <!-- class="border-bottom-info" -->
      <!-- class="border-secondary" -->
      <b-card
        v-for="(passenger, index) in passengerDataToAdd"
        :key="index"
        bg-variant="light-secondary"
        header-class="px-25 py-0"
        class="mb-50 mb-md-1"
        no-body
      >
        <!-- ANCHOR - HEADER -->
        <template #header>
          <b-row
            class="w-100"
            no-gutters
          >
            <b-col
              cols="12"
              class="w-100 d-flex-between text-nowrap px-75"
            >
              <div>
                <span :class="`text-body ${isMobileView ? 'font-small-4' : 'font-medium-2'} fw-700`">
                  {{ resolveHeaderNamePassenger(passenger, index) }}
                </span>
              </div>

              <div class="d-flex align-items-end align-items-lg-center justify-content-center">
                <div class="d-flex justify-content-around m-end">
                  <b-button
                    v-if="!isEmpty(selectedTrip) && !selectedTrip.every(trip => SOURCE_DISABLE_ADD_MORE_PAXS.includes(trip.source)) && !isHideFunction"
                    variant="flat-danger"
                    class="px-50 py-25"
                    @click="handleDeletePax(passenger)"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="20"
                      class="fw-600"
                    />
                  </b-button>
                </div>
              </div>
            </b-col>

            <!-- ANCHOR - Honorific -->
            <b-col
              cols="2"
              lg="1"
              class="px-0 px-md-50"
            >
              <validation-provider
                #default="validationContext"
                name="Honorific"
                rules="required"
                :vid="`Honorific-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  class="mb-0"
                  :state="getValidationState(validationContext) === false ? false : null"
                >
                  <v-select
                    :id="`Honorific-${passenger.paxType}-${index}`"
                    v-model="passenger.title"
                    :options="titleOptions[passenger.paxType]"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :class="`select-size-sm honorific_select ${isMobileView ? 'font-small-2' : 'font-medium-1'} font-weight-bolder`"
                    :placeholder="`${$t('flight.Honorific')} (*)`"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span class="">
                        {{ $t(data.text) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span class="text-nowrap">
                        {{ $t(data.text) }}
                      </span>
                    </template>
                  </v-select>

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`Honorific-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - Full name -->
            <b-col
              v-if="isCombineFullName"
              cols="10"
              lg="8"
              class="px-0 px-md-50"
            >
              <HStack class="w-100">
                <validation-provider
                  #default="validationContext"
                  :rules="`required|alphaSpaces|minWord:2,${selectedTrip.some(trip => ['1A'].includes(trip.source)) ? '2' : '1'}`"
                  :name="$t('flight.fullName')"
                  class="flex-1"
                  :vid="`fullName-${passenger.paxType}-${index}`"
                >
                  <vue-autosuggest
                    :id="`fullName-${passenger.paxType}-${index}`"
                    v-model="passenger.fullName"
                    :suggestions="passenger.paxType ? paxFilteredSuggestions[passenger.paxType] : []"
                    :input-props="{
                      placeholder: `${$t('flight.fullName')} (*)`,
                      state: `${getValidationState(validationContext) === false ? false : null}`,
                      class: `form-control text-body px-25 font-weight-bolder ${isMobileView ? 'font-small-4' : 'font-medium-2'} ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`
                    }"
                    :section-configs="sectionConfigs"
                    :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion, 'fullName')"
                    @focus="setCurrentPaxIndex(index)"
                    @input="$event => {
                      passenger.fullName = formatterInput($event);
                      debounceSearchCustomerContact(passenger.fullName);
                    }"
                    @blur="splitFullNameHandle(passenger)"
                  >
                    <template slot-scope="{ suggestion }">
                      <div class="cursor-pointer">
                        <b-row>
                          <b-col :cols="suggestion.item?.owner ? 6 : 12">
                            <div class="text-nowrap font-italic small">
                              {{ suggestion.item.type ? $t(`reservation.${suggestion.item.type}`) : '' }}
                            </div>
                            <div class="text-uppercase">
                              <b v-html="highlightSearchString(getLocaleFullName(suggestion.item), passenger.fullName)" />
                            </div>
                            <div class="text-nowrap">
                              {{ suggestion.item.birthDay ? convertISODateTime(suggestion.item.birthDay).date : '' }}
                            </div>
                            <div
                              v-if="suggestion.item?.identifyNumber"
                              class="text-nowrap"
                            >
                              CCCD/PP: <span class="fw-600">{{ suggestion.item.identifyNumber }}</span>
                            </div>
                          </b-col>
                          <b-col
                            v-if="suggestion.item?.owner"
                            cols="6"
                            class="text-14px"
                          >
                            <small class="font-italic">{{ $t('customer.Customer') }}:</small>
                            <div class="d-flex gap-1">
                              <b>{{ suggestion.item?.owner?.lastName }} {{ suggestion.item?.owner?.firstName }}</b>
                              ({{ suggestion.item?.owner?.code }})
                            </div>
                            <div>{{ suggestion.item?.owner?.emailAddress }}</div>
                            <div>{{ suggestion.item?.owner?.phoneNumber }}</div>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </vue-autosuggest>

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`fullName-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </validation-provider>
                <IAmIcon
                  :id="`help-${passenger.paxType}-${index}`"
                  icon="infoCircleOutline"
                  size="16"
                  class="cursor-pointer"
                  color="#EF5350"
                />
                <b-tooltip
                  :target="`help-${passenger.paxType}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  custom-class="tooltip-info-class"
                  boundary="viewport"
                  variant="light"
                >
                  <div v-html="$t('flight.combineFullNameHelp')" />
                </b-tooltip>
              </HStack>
            </b-col>

            <!-- ANCHOR - Last name -->
            <b-col
              v-if="!isCombineFullName"
              cols="4"
              :lg="!isDobInputOutsideDetailBox ? 5 : 3"
              class="px-0 px-md-50"
            >
              <validation-provider
                #default="validationContext"
                :rules="`required|alphaSpaces|min:${selectedTrip.some(trip => ['1A'].includes(trip.source)) ? '2' : '1'}`"
                name="Last Name"
                :vid="`lastName-${passenger.paxType}-${index}`"
              >
                <vue-autosuggest
                  :id="`lastName-${passenger.paxType}-${index}`"
                  v-model="passenger.lastName"
                  :suggestions="passenger.paxType ? paxFilteredSuggestions[passenger.paxType] : []"
                  :input-props="{
                    placeholder: `${$t('flight.lastName')} (*)`,
                    state: `${getValidationState(validationContext) === false ? false : null}`,
                    class: `form-control text-body px-25 font-weight-bolder ${isMobileView ? 'font-small-4' : 'font-medium-2'} ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`
                  }"
                  :class="isMobileView ? 'custom-last-name-autosuggest' : 'custom-last-name-autosuggest-desktop'"
                  :section-configs="sectionConfigs"
                  :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion, 'lastName')"
                  @focus="setCurrentPaxIndex(index)"
                  @input="$event => {
                    passenger.lastName = formatterInput($event);
                    debounceSearchCustomerContact(passenger.lastName);
                  }"
                  @blur="() => { passenger.lastName = passenger.lastName.trim() }"
                >
                  <template slot-scope="{ suggestion }">
                    <div class="cursor-pointer">
                      <b-row>
                        <b-col :cols="suggestion.item?.owner ? 6 : 12">
                          <div class="text-nowrap font-italic small">
                            {{ suggestion.item.type ? $t(`reservation.${suggestion.item.type}`) : '' }}
                          </div>
                          <div class="text-uppercase">
                            <b v-html="highlightSearchString(getLocaleFullName(suggestion.item), passenger.lastName)" />
                          </div>
                          <div class="text-nowrap">
                            {{ suggestion.item.birthDay ? convertISODateTime(suggestion.item.birthDay).date : '' }}
                          </div>
                          <div
                            v-if="suggestion.item?.identifyNumber"
                            class="text-nowrap"
                          >
                            CCCD/PP: <span class="fw-600">{{ suggestion.item.identifyNumber }}</span>
                          </div>
                        </b-col>
                        <b-col
                          v-if="suggestion.item?.owner"
                          cols="6"
                          class="text-14px"
                        >
                          <small class="font-italic">{{ $t('customer.Customer') }}:</small>
                          <div :class="`d-flex gap-1 ${isMobileView ? 'flex-column' : ''}`">
                            <b class="text-nowrap">{{ suggestion.item?.owner?.lastName }} {{ suggestion.item?.owner?.firstName }}</b>
                            <small>({{ suggestion.item?.owner?.code }})</small>
                          </div>
                          <div>{{ suggestion.item?.owner?.emailAddress }}</div>
                          <div>{{ suggestion.item?.owner?.phoneNumber }}</div>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </vue-autosuggest>

                <b-tooltip
                  v-if="validationContext.errors[0]"
                  :target="`lastName-${passenger.paxType}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - First name -->
            <b-col
              v-if="!isCombineFullName"
              cols="6"
              :lg="!isDobInputOutsideDetailBox ? 6 : 5"
              class="px-0 px-md-50"
            >
              <validation-provider
                #default="validationContext"
                name="First Name"
                :rules="`required|alphaSpaces|min:${selectedTrip.some(trip => ['1A'].includes(trip.source)) ? '2' : '1'}`"
                :vid="`firstName-${passenger.paxType}-${index}`"
              >
                <!-- form-control-sm -->
                <vue-autosuggest
                  :id="`firstName-${passenger.paxType}-${index}`"
                  v-model="passenger.firstName"
                  :suggestions="passenger.paxType ? paxFilteredSuggestions[passenger.paxType] : []"
                  :input-props="{
                    placeholder: `${$t('flight.firstName')} (*)`,
                    state: `${getValidationState(validationContext) === false ? false : null}`,
                    class: `form-control text-body px-25 ${isMobileView ? 'font-small-4' : 'font-medium-2'} font-weight-bolder ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`
                  }"
                  :section-configs="sectionConfigs"
                  :class="{'custom-first-name-autosuggest': isMobileView}"
                  :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion, 'firstName')"
                  @focus="setCurrentPaxIndex(index)"
                  @input="$event => {
                    passenger.firstName = formatterInput($event);
                    debounceSearchCustomerContact(passenger.firstName);
                  }"
                  @blur="() => { passenger.firstName = passenger.firstName.trim() }"
                  @select="() => { passenger.firstName = passenger.firstName.trim() }"
                >
                  <template slot-scope="{ suggestion }">
                    <div class="cursor-pointer">
                      <b-row>
                        <b-col :cols="suggestion.item?.owner ? 6 : 12">
                          <div class="text-nowrap font-italic small">
                            {{ suggestion.item.type ? $t(`reservation.${suggestion.item.type}`) : '' }}
                          </div>
                          <div class="text-uppercase">
                            <b v-html="highlightSearchString(getLocaleFullName(suggestion.item), passenger.firstName)" />
                          </div>
                          <div class="text-nowrap">
                            {{ suggestion.item.birthDay ? convertISODateTime(suggestion.item.birthDay).date : '' }}
                          </div>
                          <div
                            v-if="suggestion.item?.identifyNumber"
                            class="text-nowrap"
                          >
                            CCCD/PP: <span class="fw-600">{{ suggestion.item.identifyNumber }}</span>
                          </div>
                        </b-col>
                        <b-col
                          v-if="suggestion.item?.owner"
                          cols="6"
                          class="text-14px"
                        >
                          <small class="font-italic">{{ $t('customer.Customer') }}:</small>
                          <div :class="`d-flex gap-1 ${isMobileView ? 'flex-column' : ''}`">
                            <b>{{ suggestion.item?.owner?.lastName }} {{ suggestion.item?.owner?.firstName }}</b>
                            ({{ suggestion.item?.owner?.code }})
                          </div>
                          <div>{{ suggestion.item?.owner?.emailAddress }}</div>
                          <div>{{ suggestion.item?.owner?.phoneNumber }}</div>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </vue-autosuggest>

                <b-tooltip
                  v-if="validationContext.errors[0]"
                  :target="`firstName-${passenger.paxType}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - Birthday -->
            <b-col
              v-if="isDobInputOutsideDetailBox || isCombineFullName"
              cols="12"
              lg="3"
              class="px-0 px-md-50 mt-25 mt-lg-0"
            >
              <!-- :class="isMobileView ? 'mt-25' : ''" -->
              <validation-provider
                #default="validationContext"
                :name="$t('flight.dob')"
                :rules="(passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title)) ? getValidateBirthday('CHILD', passenger) : getValidateBirthday(passenger.paxType, passenger)"
                :vid="`dob-${passenger.paxType}-${passenger.paxId}`"
              >
                <b-form-group
                  :id="`bd-${passenger.paxType}-${passenger.paxId}`"
                  class="mb-0"
                >
                  <flat-pickr
                    v-model="passenger.birthday"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      :id="`dob-${passenger.paxType}-${passenger.paxId}`"
                      v-model="passenger.birthday"
                      :name="$t('flight.dob')"
                      class="form-control px-50 py-0"
                      :config="{
                          ...configFlatPickr,
                          altInputClass: 'form-control input font_birthday_passenger  font-weight-bolder px-50 py-0 border-transparent',
                      }"
                      :style="{ height: '30px' }"
                      :placeholder="`${$t('flight.dob')} ${getValidateBirthday(passenger.paxType, passenger).includes('required') ? '(*)' : ''}`"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`bd-${passenger.paxType}-${passenger.paxId}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - BUTTON: Chi tiết -->
            <b-col
              cols="12"
              :class="`d-flex-center ${isDobInputOutsideDetailBox ? 'justify-content-start':'justify-content-end'}`"
            >
              <div
                v-b-toggle="`collapse-${passenger.paxType}-${index}`"
                class="d-flex-center px-50 py-25 gap-2 hover-underline text-info btn btn-flat-info my-25 mx-50 rounded"
                :class="isDobInputOutsideDetailBox && !isMobileView ? 'font-medium-2' : 'font-small-3'"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class=""
                />
                <span>
                  {{ isDobInputOutsideDetailBox ? $t('flight[\'Additional information\']') : $t('flight.Details') }}
                </span>
              </div>
            </b-col>
          </b-row>
        </template>

        <!-- SECTION - COLLAPSE -->
        <b-collapse
          :id="`collapse-${passenger.paxType}-${index}`"
          :visible="['INFANT'].includes(passenger.paxType)
            || getValidateBirthday(passenger.paxType, passenger).includes('required')
            || (selectedTrip.some(item => ['VJ'].includes(item?.source)) && passenger.paxType === 'ADULT')
            || (isEnableFormPassport() && getValidatePassport(passenger, 'number').includes('required'))
            || selectedTrip.some(item => ['TR'].includes(item?.source))
          "
        >
          <div class="pb-50">
            <!-- ANCHOR - Birthday, parentPaxId -->
            <b-row class="mx-0">
              <!-- ANCHOR - Birthday -->
              <b-col
                v-if="!isDobInputOutsideDetailBox && !isCombineFullName"
                cols="12"
                sm="6"
                :class="isMobileView ? 'px-25' : ''"
                class="my-25"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('flight.dob')"
                  :rules="(passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title)) ? getValidateBirthday('CHILD', passenger) : getValidateBirthday(passenger.paxType, passenger)"
                  :vid="`dob-${passenger.paxType}-${passenger.paxId}`"
                >
                  <b-form-group
                    :id="`bd-${passenger.paxType}-${passenger.paxId}`"
                    class="mb-0"
                  >
                    <flat-pickr
                      v-model="passenger.birthday"
                      class="d-none"
                    />
                    <div
                      class="form-control p-0"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        :id="`dob-${passenger.paxType}-${passenger.paxId}`"
                        v-model="passenger.birthday"
                        :name="$t('flight.dob')"
                        class="form-control px-50 py-0"
                        :config="{
                          ...configFlatPickr,
                          altInputClass: 'form-control input font_birthday_passenger  font-weight-bolder px-50 py-0 border-transparent',
                        }"
                        :style="{ height: '30px' }"
                        :placeholder="`${$t('flight.dob')} ${getValidateBirthday(passenger.paxType, passenger).includes('required') ? '(*)' : ''}`"
                      />
                    </div>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`bd-${passenger.paxType}-${passenger.paxId}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="viewport"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR - người lớn đi cùng em bé -->
              <b-col
                v-if="passenger.paxType === 'INFANT'"
                cols="12"
                sm="6"
                :class="isMobileView ? 'px-25' : ''"
              >
                <validation-provider
                  #default="validationContext"
                  name="Người lớn đi cùng"
                  :rules="(passenger.paxType === 'INFANT') ? `required|duplicateParentId:${parentSelected.toString()}` : ''"
                  :vid="`parent-id-select-${passenger.paxId}`"
                >
                  <b-form-group
                    size="sm"
                    class="mb-0"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <v-select
                      v-if="passenger.paxType === 'INFANT'"
                      :id="`parent-id-select-${passenger.paxId}`"
                      v-model="passenger.parentPaxId"
                      :options="parentOptions"
                      :reduce="val => val.paxId"
                      :clearable="false"
                      :placeholder="`${$t('flight.selectAdult')} (*)`"
                      class="honorific_select"
                      label="firstName"
                      :disabled="isDisabledSelectParentPaxId"
                    >
                      <template #option="data">
                        <span :class="`${isMobileView ? 'font-small-2' : 'font-small-3'}`">
                          {{
                            data.firstName && data.lastName
                              ? `${data.title} ${data.lastName} ${data.firstName}`
                              : `${data.paxType} #${data.paxId}`
                          }}
                        </span>
                      </template>
                      <template #selected-option="data">
                        <span :class="`${isMobileView ? 'font-small-2' : 'font-small-3'}`">
                          {{
                            data.firstName && data.lastName
                              ? `${data.title} ${data.lastName} ${data.firstName}`
                              : `${data.paxType} #${data.paxId}`
                          }}
                        </span>
                      </template>
                    </v-select>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`parent-id-select-${passenger.paxId}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="viewport"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                    <div>
                      <i :class="`text-danger font-weight-bolder ${isMobileView ? 'font-small-2' : 'font-small-3'}`">
                        * {{ $t('flight.requiredAdult') }}
                      </i>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- ANCHOR - PASSPORT -->

            <b-row
              v-if="isEnableFormPassport()"
              class="mx-0 pb-50 px-25 px-md-1 passport_class"
              no-gutters
            >
              <!-- ANCHOR passport (Select) ['CCCD', 'Passport'] -->
              <b-col
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <b-form-select
                  id="passenger.docTypeSelect"
                  v-model="passenger.docTypeSelect"
                  class="fw-700"
                >
                  <b-form-select-option
                    v-if="selectedTrip.some(trip => ['VJ'].includes(trip?.source) && isDomesticTrips)"
                    value="C"
                  >
                    Căn cước công dân
                  </b-form-select-option>
                  <!-- v-if="!(isDomesticTrips && selectedTrip.some(trip => ['VJ'].includes(trip?.source)))" -->
                  <b-form-select-option value="P">
                    Hộ chiếu
                  </b-form-select-option>
                </b-form-select>
              </b-col>

              <!-- ANCHOR passport (number) Số hộ chiếu -->
              <b-col
                sm="6"
                :md="['C'].includes(passenger.docTypeSelect) ? '5' : '4'"
                :lg="['C'].includes(passenger.docTypeSelect) ? '4' : '3'"
                xl="4"
                class="d-flex gap-1"
              >
                <validation-provider
                  #default="validationContext"
                  :rules="`${getValidatePassport(passenger, 'number')}|alphaNum`"
                  :name="['C'].includes(passenger.docTypeSelect) ? $t('flight.CCCD') : $t('flight.Passport')"
                  :vid="`passport-${passenger.paxType}-${index}`"
                  class="flex-1"
                >
                  <b-form-group
                    :id="`passport-${passenger.paxType}-${index}`"
                    class="mb-0"
                  >
                    <!-- size="sm" -->
                    <b-form-input
                      :id="`passport-${passenger.paxType}-${index}`"
                      v-model="passenger.PaxDocuments[0].number"
                      :state="getValidationState(validationContext) === false ? false : null"
                      lazy-formatter
                      :formatter="trimAllInputAndUpper"
                      :placeholder="`${['C'].includes(passenger.docTypeSelect) ? $t('flight.CCCD') : $t('flight.Passport')} ${getValidatePassport(passenger, 'number').includes('required') ? '(*)' : ''}`"
                    />
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-${passenger.paxType}-${index}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="viewport"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
                <BButton
                  v-b-tooltip.hover.focus.top="`Quét QR Code CCCD/Passport`"
                  variant="flat-primary"
                  class="rounded-circle p-50"
                  @click="onClickQrScanHandle(`qr-code-scan-passport-${passenger.paxType}-${index}`)"
                >
                  <IAmIcon
                    icon="qrCode"
                    size="18"
                    color="black"
                  />
                </BButton>

                <IAmQrCodeScan
                  :idProp="`qr-code-scan-passport-${passenger.paxType}-${index}`"
                  @apply="value => passenger.PaxDocuments[0].number = value"
                />

                <BButton
                  v-b-tooltip.hover.focus.top="`Tải Ảnh để quét QR Code CCCD/Passport`"
                  variant="flat-primary"
                  class="rounded-circle p-50"
                  @click="onClickQrScanHandle(`qr-code-scan-by-upload-passport-${passenger.paxType}-${index}`)"
                >
                  <IAmIcon
                    icon="cloudUploadOutline"
                    size="18"
                    color="black"
                  />
                </BButton>

                <IAmQrCodeScanByUpload
                  :idProp="`qr-code-scan-by-upload-passport-${passenger.paxType}-${index}`"
                  @apply="value => passenger.PaxDocuments[0].number = value"
                />
              </b-col>

              <!-- ANCHOR passport (Nationality) Mã quốc gia -->
              <b-col
                v-if="['P'].includes(passenger.docTypeSelect)"
                sm="6"
                md="4"
                lg="3"
              >
                <validation-provider
                  #default="validationContext"
                  :rules="getValidatePassport(passenger, 'nationality')"
                  :name="$t('flight.Nationality')"
                  :vid="`nationality-${passenger.paxType}-${index}`"
                >
                  <IAmCountrySelect
                    :id="`nationality-${passenger.paxType}-${index}`"
                    v-model="passenger.PaxDocuments[0].nationality"
                    class="bg-white"
                    :placeholder="`Mã quốc gia ${getValidatePassport(passenger, 'nationality').includes('required') ? '(*)' : ''}`"
                    :contextErrors="validationContext.errors[0]"
                  />
                </validation-provider>
              </b-col>

              <!-- ANCHOR passport (isCountry) Nơi cấp -->
              <b-col
                v-if="['P'].includes(passenger.docTypeSelect)"
                sm="6"
                md="4"
                lg="3"
              >
                <validation-provider
                  #default="validationContext"
                  :rules="getValidatePassport(passenger, 'isCountry')"
                  name="Nơi cấp"
                  :vid="`passport-place-issued-${passenger.paxType}-${index}`"
                >
                  <IAmCountrySelect
                    :id="`passport-place-issued-${passenger.paxType}-${index}`"
                    v-model="passenger.PaxDocuments[0].isCountry"
                    class="bg-white"
                    :placeholder="`Nơi cấp ${getValidatePassport(passenger, 'isCountry').includes('required') ? '(*)' : ''}`"
                    :contextErrors="validationContext.errors[0]"
                  />
                </validation-provider>
              </b-col>

              <!-- ANCHOR passport (expDate) Ngày hết hạn -->
              <b-col
                v-if="['P'].includes(passenger.docTypeSelect)"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <validation-provider
                  #default="validationContext"
                  :rules="getValidatePassport(passenger, 'endDate')"
                  :name="$t('flight.expirationDate')"
                  :vid="`passport-expirationDate-${passenger.paxType}-${index}`"
                >
                  <b-form-group
                    :id="`passport-exDate-${passenger.paxType}-${index}`"
                    class="mb-0"
                  >
                    <flat-pickr
                      v-model="passenger.PaxDocuments[0].endDate"
                      class="d-none"
                    />
                    <div
                      class="form-control p-0"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        :id="`passport-expirationDate-${passenger.paxType}-${index}`"
                        v-model="passenger.PaxDocuments[0].endDate"
                        :name="$t('flight.expirationDate')"
                        class="form-control px-50 py-0"
                        :config="{
                          ...configFlatPickrPassport,
                          altInputClass: 'form-control input px-50 py-0 border-transparent',
                        }"
                        :placeholder="`${$t('flight.expirationDate')} ${getValidatePassport(passenger, 'endDate').includes('required') ? '(*)' : ''}`"
                      />
                    </div>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-exDate-${passenger.paxType}-${index}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="viewport"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR passport (gender) giới tính -->
              <b-col
                v-if="['P'].includes(passenger.docTypeSelect)"
                sm="6"
                md="4"
                lg="3"
              >
                <validation-provider
                  #default="validationContext"
                  :rules="getValidatePassport(passenger, 'gender')"
                  :name="$t('flight.gender')"
                  :vid="`passport-gender-${passenger.paxType}-${index}`"
                >
                  <b-form-group
                    :id="`passport-gender-${passenger.paxType}-${index}`"
                    class="mb-0"
                  >
                    <!-- class="w-100 bg-white select-size-sm p-0" -->
                    <v-select
                      v-model="passenger.PaxDocuments[0].gender"
                      :reduce="val => val.value"
                      label="label"
                      class="w-100 bg-white p-0"
                      :class="validationContext.errors[0] ? 'border-danger-select' : ''"
                      :options="genderOptions.filter(item => item.value !== 'OTHER')"
                      :placeholder="`${$t('flight.gender')} ${getValidatePassport(passenger, 'gender').includes('required') ? '(*)' : ''}`"
                    >
                      <template #option="data">
                        <span>
                          {{ $t(data.label) }}
                        </span>
                      </template>
                      <template #selected-option="data">
                        <span>
                          {{ $t(data.label) }}
                        </span>
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`passport-gender-${passenger.paxType}-${index}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="viewport"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- ANCHOR: Thêm cảnh báo "hộ chiếu sắp hết hạn" nếu thời gian hết hạn hộ chiếu < thời gian bay 6 tháng (https://discord.com/channels/1054696448110903327/1300290501815308328/1300290507137875971) -->
            <div
              v-if=" ['P'].includes(passenger.docTypeSelect) && flightMaxDate && isLastFlightDateAfterPassportExpiry( passenger.PaxDocuments[0].endDate,flightMaxDate)"
              class="text-danger mx-1  my-50  "
            >
              <small>({{ $t('reservation.isLastFlightDateAfterPassportExpiry') }})</small>
            </div>
            <div
              v-else-if="['P'].includes(passenger.docTypeSelect) && flightMaxDate && checkPassportLessThan6MonthsBeforeFlightDate(passenger.PaxDocuments[0].endDate, flightMaxDate)"
              class="text-danger mx-1 my-50"
            >
              <small>({{ $t('reservation.passportLessThan6MonthsBeforeFlight') }})</small>
            </div>
            <!-- END --- PASSPORT --- -->

            <!-- ANCHOR - THẺ THÀNH VIÊN -->
            <div
              v-if="isEnableMemberShipCardAndPassport && !['INFANT'].includes(passenger.paxType)"
              class="mt-50 px-25 px-md-1"
            >
              Thẻ thành viên, SkyJoy, Lotusmiles: <br>
              <div
                v-for="(memberCard, memberCardIndex) of passenger.memberFly"
                :key="memberCardIndex"
                class="d-flex mb-50"
              >
                <b-input-group
                  :id="`member-Fly-${passenger.paxType}-${index}-${memberCardIndex}`"
                  class="border rounded"
                  style="max-width: 576px"
                >
                  <b-input-group-prepend
                    :id="`member-Fly-${passenger.paxType}-${index}`"
                    :class="isMobileView ? 'flex-wrap' : ''"
                  >
                    <!-- <b-form-select
                      :id="`member-Fly-${passenger.paxType}-${index}`"
                      v-model="memberCard.airline"
                      :options="getAirlineOptionsGroupByTrips(selectedTrip, memberCard.airline, index, memberCardIndex)"
                      class="pl-50 fw-700"
                    /> -->

                    <v-select
                      :id="`member-Fly-${passenger.paxType}-${index}`"
                      v-model="memberCard.airline"
                      :options="getAirlineOptionsGroupByTrips(selectedTrip, memberCard.airline, index, memberCardIndex)"
                      style="width: 200px; max-height: 38px;"
                      label="text"
                      class="membership-airline-select rounded mr-25"
                      :class="isMobileView ? 'border-secondary' : ''"
                      :clearable="false"
                      :placeholder="`${$t('flight.airline')}`"
                      :reduce="val => val.value"
                    >
                      <template #option="data">
                        <span :class="`${isMobileView ? 'font-small-2' : 'font-small-3'} my-25 font-weight-bold`">
                          {{ data.text ?? data.value }}
                        </span>
                      </template>
                      <template #selected-option="data">
                        <span
                          :class="`font-weight-bolder text-nowrap text-truncate ${isMobileView ? 'font-small-2' : 'font-small-3'}`"
                          style="max-width: 200px;"
                        >
                          {{ data.text ?? data.value }}
                        </span>
                      </template>
                    </v-select>
                  </b-input-group-prepend>

                  <validation-provider
                    #default="validationContext"
                    :name="['VJ'].includes(memberCard.airline) ? 'ttvVJ' : $t('flight.memberFly')"
                    :rules="['VJ'].includes(memberCard.airline) ? 'ttvVJ' : 'numeric'"
                    :vid="`cardNumber-${passenger.paxType}-${index}-${memberCardIndex}`"
                    class="flex-1"
                  >
                    <b-form-input
                      :id="`cardNumber-${passenger.paxType}-${index}-${memberCardIndex}`"
                      v-model="memberCard.cardNumber"
                      style="min-width: 145px;"
                      :placeholder="`${$t('flight.memberFly')}`"
                      lazy-formatter
                      :formatter="trimAllInputAndUpper"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-tooltip
                      v-if="validationContext.errors[0]"
                      :target="`cardNumber-${passenger.paxType}-${index}-${memberCardIndex}`"
                      triggers="hover focus"
                      placement="top"
                      boundary="viewport"
                      variant="danger"
                      :title="validationContext.errors[0]"
                    >
                      <span class="text-white py-25 mb-0">
                        {{ validationContext.errors[0] }}
                      </span>
                    </b-tooltip>
                  </validation-provider>
                </b-input-group>

                <b-button
                  :id="`member-Fly-btn-del-${passenger.paxType}-${index}-${memberCardIndex}`"
                  variant="flat-danger"
                  :disabled="passenger.memberFly.length < 2"
                  class="btn-icon ml-25 rounded-pill px-75"
                  size="sm"
                  pill
                  @click="passenger.memberFly.splice(memberCardIndex, 1)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </div>

              <!-- ANCHOR - Thêm thẻ -->
              <span
                class="px-50 py-25 hover-underline text-info btn btn-flat-info my-25 mr-50 rounded"
                @click="(passenger.memberFly.length < airlineLists.length) && passenger.memberFly.push({ airline: selectedTrip[0].airline, cardNumber: null })"
              >
                <feather-icon icon="PlusIcon" />
                Thêm thẻ
              </span>

              <!-- ANCHOR Modal RegisterMembership -->
              <span
                v-if="['ADULT'].includes(passenger.paxType) && selectedTrip.some(trip => ['VN1A', 'QH'].includes(trip.source)) && !isHideFunction"
                class="px-50 py-25 hover-underline text-success btn btn-flat-success my-25 rounded"
                @click="handleOpenModalRegisterMembershipCard({ index, passenger }, $event)"
              >
                {{ $t('flight.registerMembershipCard') }}
              </span>

              <!-- <b-button
                v-if="!isEmpty(selectedTrip) && selectedTrip.some(trip => ['VN1A', 'QH'].includes(trip.source)) && !isHideFunction"
                :id="`member-FlyBtn-${passenger.paxType}-${index}`"
                variant="flat-info"
                class="py-25 mt-50 mt-md-0"
                :class="(passenger.paxType !== 'ADULT') ? 'd-none' : 'd-block'"
                @click="handleOpenModalRegisterMembershipCard({ index, passenger }, $event)"
              >
                <div class="d-flex-center">
                  <feather-icon
                    icon="PlusSquareIcon"
                    size="20"
                    class="mr-50"
                  />
                  <div>{{ $t('flight.registerMembershipCard') }}</div>
                </div>
              </b-button> -->
            </div>
          </div>
        </b-collapse>
      </b-card>

      <ModalRegisterMembershipCard
        :member-fly-data.sync="memberFlyData"
        :data-search-flight="dataSearchFlight"
        :pax-data="paxDataSelect"
        :airline-lists="airlineLists"
        :place="'create_booking'"
      />
      <!-- :max-adults="dataSearchFlight.adult" -->
      <ModalQuickInputMultiPax
        :max-adults="getMaxLengthPax('adult')"
        :max-children="getMaxLengthPax('child')"
        :max-infants="getMaxLengthPax('infant')"
        :is-create-group-booking="isCreateGroupBooking"
        @addMultiplePassengers="addMultiplePassengers"
      />
      <SuggestShopVJModal
        v-if="true"
        :passenger-data-to-add.sync="passengerDataToAdd"
        :airline-code.sync="selectedTrip[0].airline"
        :segment-list="segmentList"

        @set-visible="onSetVisible"
      />
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormSelectOption,
  BAlert,
  BButton,
  BCollapse,
  VBToggle,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import Ripple from 'vue-ripple-directive'
import {
  computed, ref, watch, toRefs, provide, onMounted, set,
} from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { fakerVI } from '@faker-js/faker'

import {
  skyTeamAirlineGroupOptions,
  oneWorldAirlineGroupOptions,
  starAllianceAirlineGroupOptions,
  genderOptions,
} from '@/constants/selectOptions'
import { titleOptions } from '@/constants/reservation'
import { apiCustomer, apiEmployee } from '@/api'
import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'
import VueI18n from '@/libs/i18n'
import store from '@/store'
import { env } from '@/libs/env'
import {
  checkPassportLessThan6MonthsBeforeFlightDate, convertISODateTime, getMaxTime, isLastFlightDateAfterPassportExpiry,
} from '@/@core/utils/filter'

import { removeAccents, trimAllInputAndUpper } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  required, min, max, email, isUnique, duplicateParentId, alphaSpaces, minWord, alphaNum,
} from '@validations'

import useCreateBookingHandle from './useCreateBookingHandle'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BAlert,
    BTooltip,
    BCollapse,
    BFormSelect,
    BFormSelectOption,

    vSelect,
    VueAutosuggest,
    flatPickr,

    ValidationObserver,
    ValidationProvider,

    IAmQrCodeScan: () => import('@/components/IAmQrCodeScan.vue'),
    IAmQrCodeScanByUpload: () => import('@/components/IAmQrCodeScanByUpload.vue'),
    ModalRegisterMembershipCard: () => import('./ModalRegisterMembershipCard.vue'),
    ModalQuickInputMultiPax: () => import('./ModalQuickInputMultiPax.vue'),
    BookingAddMorePassenger: () => import('./BookingAddMorePassenger.vue'),
    SuggestShopVJModal: () => import('./SuggestShopVJModal.vue'),
    IAmCountrySelect: () => import('@/components/IAmCountrySelect.vue'),
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    customerId: {
      type: Number,
      default: 0,
      required: true,
    },
    passengerDataToAdd: {
      type: Array,
      required: true,
    },
    dataSearchFlight: {
      type: Object,
      default: () => { },
    },
    selectedTrip: {
      type: Array,
      required: true,
    },
    isSearchClassBooking: {
      type: Boolean,
      default: false,
    },
    isCreateGroupBooking: {
      type: Boolean,
      default: false,
    },
    paxDataGroupBooking: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    const {
      mamaShortName, isDevelopment, isDobInputOutsideDetailBox,
    } = env
    const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])

    const isCombineFullName = computed(() => employeeConfig.value?.isInputFullNamePax)
    const { refFormObserver, getValidationState } = formValidation()
    const {
      selectedTrip, dataSearchFlight, isCreateGroupBooking, paxDataGroupBooking,
    } = toRefs(props)

    const segmentList = computed(() => [...new Set(selectedTrip.value.flatMap(flight => [
      flight.departure.IATACode,
      flight.arrival.IATACode,
      ...flight.segments.flatMap(segment => [
        segment.departure.IATACode,
        segment.arrival.IATACode,
      ]),
    ]))].join(','))

    const {
      isHideFunction,
      VJ_CHINA_REQUIRED_PASSPORT_IATA_CODES,
    } = useCreateBookingHandle()

    const sources = computed(() => props.selectedTrip?.map(trip => trip.source) || [])

    const airlineLists = computed(() => {
      if (!isEmpty(selectedTrip.value)) {
        const lists = selectedTrip.value.flatMap(t => t.airline.split(' | '))
        return [...new Set(lists)]
      }
      return []
    })

    const SOURCE_DISABLE_ADD_MORE_PAXS = ['VN1A', 'AK', '1A', '1B', 'TR', 'JQ']
    const suggestVisible = ref(false)
    const onSetVisible = data => {
      suggestVisible.value = data
    }
    // format input
    function formatterInput(value) {
      const str = removeAccents(value)
      return str.toUpperCase().replace(/\s+/g, ' ')
    }

    function formatterCardNumber(value) {
      const str = Number(value)
      if (!str) return null
      return Number(value)
    }

    const parentOptions = computed(() => props.passengerDataToAdd.filter(parent => parent.paxType === 'ADULT'))
    const parentSelected = computed(() => props.passengerDataToAdd.filter(item => item.parentPaxId).map(i => parseInt(i.parentPaxId, 10)))

    const isDomesticTrips = computed(() => selectedTrip.value.every(trip => trip?.domestic))
    // const isEnableMemberShipCardAndPassport = computed(() => selectedTrip.value.some(trip => !(['VN1A', 'TR', 'JQ'].includes(trip.source) && ['INTERNAL'].includes(trip.clientId))))
    const isEnableMemberShipCardAndPassport = computed(() => selectedTrip.value.some(trip => !(['TR', 'JQ'].includes(trip.source) && ['INTERNAL'].includes(trip.clientId))))
    const isVJTripFromOrToCN = computed(() => selectedTrip.value.some(trip => ['VJ'].includes(trip?.source) && trip.segments.some(seg => VJ_CHINA_REQUIRED_PASSPORT_IATA_CODES.has(seg?.departure?.IATACode) || VJ_CHINA_REQUIRED_PASSPORT_IATA_CODES.has(seg?.arrival?.IATACode))))

    function isEnableFormPassport() {
      const hasTripVJ = selectedTrip.value.some(trip => ['VJ'].includes(trip?.source))

      const validateDomestic = hasTripVJ || !isDomesticTrips.value
      // const validateInfant = isVJTripFromOrToCN.value || !['INFANT'].includes(paxType)

      return isEnableMemberShipCardAndPassport.value && validateDomestic // && validateInfant
    }

    const getValidateBirthday = (paxType, passenger) => {
      let isHavePassport = false
      if (
        ['P'].includes(passenger.docTypeSelect)
        && (passenger?.PaxDocuments[0]?.number
        || passenger?.PaxDocuments[0]?.nationality
        || passenger?.PaxDocuments[0]?.isCountry
        || passenger?.PaxDocuments[0]?.endDate)) { isHavePassport = true }

      const { type, flights, flight } = props.dataSearchFlight

      // F1, TH, AK require DOB
      const isRequiredDOB = selectedTrip.value.some(trip => ['F1', 'TH', 'AK'].includes(trip?.source))

      // validate hành trình (SGN-PVG, PVG-SGN) là bắt buộc nhập dob (cho passport VJ)
      const isRequireAdultVJSGNPVG = !isEmpty(selectedTrip.value) && selectedTrip.value.some(trip => ['VJ'].includes(trip.source)) && !isEmpty(flights) && flights.some(flight => (['PVG'].includes(flight.startPoint) && ['SGN'].includes(flight.endPoint)) || (['SGN'].includes(flight.startPoint) && ['PVG'].includes(flight.endPoint)))

      const isRequireChild = selectedTrip.value.some(item => ['1G', 'QH', 'VN1A', '1A'].includes(item?.source))

      if (flights || flight) {
        const departDate = flight ? flight.departDate : flights[0].departDate
        const validationRules = {
          ADULT: `${isRequiredDOB || isRequireAdultVJSGNPVG || isHavePassport ? 'required|' : ''}ageGroup:${type},${departDate},${paxType}`,
          CHILD: `${isRequiredDOB || isRequireChild || isHavePassport ? 'required|' : ''}ageGroup:${type},${departDate},${paxType}`,
          INFANT: `required|ageGroup:${type},${departDate},${paxType}`,
        }
        return validationRules[paxType]
      }

      return ''
    }

    /* *** PASSPORT ***
    1. Source AK:
      - PASSPORT:
          + required passport với ADULT và CHILD.
          + INFANT required field ['nationality'], các field ['number', 'isCountry', 'endDate'] required khi 1 trong 3 được nhập

    2. Source TR:
      - PASSPORT: Tất cả các pax required field ['nationality', 'isCountry',] các field ['number', 'endDate'] required khi 1 trong 2 được nhập

    3. Source VJ:
      - CCCD: Tất cả các pax chỉ required field 'number'
      - PASSPORT: required khi nhập 1 trong 4 field ['number', 'nationality', 'isCountry', 'endDate']

    3. Source 1A:
      - PASSPORT: Required khi nhập 1 trong 5 field ['number', 'nationality', 'isCountry', 'endDate', 'gender']

    4. Các hãng khác:
      - PASSPORT: Chỉ required khi nhập 1 trong 4 field ['number', 'nationality', 'isCountry', 'endDate']

      */

    //  => bên payload book còn validate 1 ít nữa phần pp của pax

    const AIRLINE_REQUIRED_PASSPORT = ['3U', '5J'] // của F1 cũ
    const PASSPORT_FIELDS_REQUIRED_TR = ['nationality', 'isCountry']
    const PASSPORT_FIELDS_1A = ['number', 'nationality', 'isCountry', 'endDate', 'gender']
    const COMMON_PASSPORT_FIELDS = ['number', 'nationality', 'isCountry', 'endDate']

    const getValidatePassport = (pax, field = '') => {
      // Source: F1
      if (sources.value.includes('F1') && airlineLists.value.some(airline => AIRLINE_REQUIRED_PASSPORT.includes(airline))) {
        return 'required'
      }

      // Source: TR
      if (sources.value.includes('TR')) {
        if (PASSPORT_FIELDS_REQUIRED_TR.includes(field)) {
          return 'required'
        }

        const hasInput = ['number', 'endDate'].some(it => pax?.PaxDocuments[0]?.[it])
        return ['number', 'endDate'].includes(field) && hasInput ? 'required' : ''
      }

      // Source: AK
      if (sources.value.includes('AK')) {
        if (pax.paxType === 'INFANT') {
          if (field === 'nationality') {
            return 'required'
          }

          const hasInput = ['number', 'isCountry', 'endDate'].some(it => pax?.PaxDocuments[0]?.[it])
          return ['number', 'isCountry', 'endDate'].includes(field) && hasInput ? 'required' : ''
        }

        return COMMON_PASSPORT_FIELDS.includes(field) ? 'required' : ''
      }

      // Source: VJ
      if (sources.value.includes('VJ')) {
        if (pax.docTypeSelect === 'C') {
          if (field === 'number') return '' // 'required' // FIXME tạm thời ko required https://discord.com/channels/1054696448110903327/1309020923478802432/1309351310570815578
        }

        const hasInput = COMMON_PASSPORT_FIELDS.some(it => pax?.PaxDocuments[0]?.[it])
        return COMMON_PASSPORT_FIELDS.includes(field) && (isVJTripFromOrToCN.value || hasInput) ? 'required' : ''
      }

      // Source: 1A
      if (sources.value.includes('1A')) {
        const hasInput = PASSPORT_FIELDS_1A.some(it => pax?.PaxDocuments[0]?.[it])
        return PASSPORT_FIELDS_1A.includes(field) && hasInput ? 'required' : ''
      }

      const hasInput = COMMON_PASSPORT_FIELDS.some(it => pax?.PaxDocuments[0]?.[it])
      return COMMON_PASSPORT_FIELDS.includes(field) && hasInput ? 'required' : ''
    }

    function getAirlineOptionsGroupByTrips(trips, airline, paxIndex, memberCardIndex) {
      const result = new Set()
      const addedValues = new Set()
      const airlineGroups = [skyTeamAirlineGroupOptions, oneWorldAirlineGroupOptions, starAllianceAirlineGroupOptions]

      if (isEmpty(trips)) return Array.from(result)

      const airlineArr = trips.map(item => item.airline)

      if (!airline) {
        set(props.passengerDataToAdd[paxIndex].memberFly[memberCardIndex], 'airline', airlineArr[0] || '')
      }

      airlineGroups.forEach(groupOptions => {
        if (groupOptions.some(item => airlineArr.includes(item.value))) {
          groupOptions.forEach(item => {
            if (!addedValues.has(item.value)) {
              result.add(item)
              addedValues.add(item.value)
            }
          })
        }
      })

      airlineArr.forEach(item => {
        if (!addedValues.has(item)) {
          const airlineName = store.getters['globalConfig/getAirlineNameByCode'](item) || item
          result.add({ value: item, text: `${airlineName.toUpperCase()} (${item})` })
          addedValues.add(item)
        }
      })

      return Array.from(result)
    }

    const memberFlyData = ref(null)
    const indexMemberFly = ref(null)
    const paxDataSelect = ref(null)

    function setNewDataMemberFly(indexMemberFly, field, value) {
      set(props.passengerDataToAdd[indexMemberFly.value].memberFly[indexMemberFly], field, value)
    }

    watch(() => memberFlyData.value, data => {
      if (data) {
        const checkData = data.filter(item => isNumber(item.cardNumber) && (item.cardNumber !== 0))

        if (checkData.length === 1) {
          const findAirline = !isEmpty(airlineLists.value) ? airlineLists.value.find(a => a === checkData[0].airline.code) : false
          if (!findAirline) return

          const registerAirline = data[0].airline.code
          const registerCardNumber = data[0].cardNumber

          const indexMemberFlyUpdate = props.passengerDataToAdd[indexMemberFly.value].memberFly.findIndex(it => it.airline === findAirline)
          if (indexMemberFlyUpdate !== -1) { // nếu có item trùng airline thì update cardNumber
            setNewDataMemberFly(indexMemberFlyUpdate, 'airline', registerAirline)
            setNewDataMemberFly(indexMemberFlyUpdate, 'cardNumber', registerCardNumber)
          } else {
            const indexMemberFlyEmpty = props.passengerDataToAdd[indexMemberFly.value].memberFly.findIndex(it => !it.cardNumber)
            if (indexMemberFlyEmpty !== -1) { // nếu có item chưa nhập cardNumber
              setNewDataMemberFly(indexMemberFlyEmpty, 'airline', registerAirline)
              setNewDataMemberFly(indexMemberFlyEmpty, 'cardNumber', registerCardNumber)
            } else {
              // eslint-disable-next-line vue/no-mutating-props
              props.passengerDataToAdd[indexMemberFly.value].memberFly.push({
                airline: registerAirline,
                cardNumber: registerCardNumber,
              })
            }
          }
        }

        if (checkData.length > 1) {
          const findAirline = !isEmpty(airlineLists.value) ? data.find(a => airlineLists.value.some(air => air === a.airline.code)) : false
          if (!findAirline) return

          checkData.forEach(newCard => {
            // eslint-disable-next-line vue/no-mutating-props
            props.passengerDataToAdd[indexMemberFly.value].memberFly.push({
              airline: newCard.airline,
              cardNumber: newCard.cardNumber,
            })
          })
        }

        // Thêm data để kiểm tra
        set(props.passengerDataToAdd[indexMemberFly.value], 'registerFlyCardNumbers', checkData)
      }
    }, { deep: true })

    const hidePopoverChangeQuantityPaxEvent = ref(false)

    async function handleOpenModalRegisterMembershipCard(data, event) {
      await new Promise(resolve => {
        indexMemberFly.value = data.index
        paxDataSelect.value = data.passenger
        resolve()
      })

      hidePopoverChangeQuantityPaxEvent.value = !hidePopoverChangeQuantityPaxEvent.value
      this.$root.$emit('bv::show::modal', 'modal-register-member-ship-card', event)
    }

    function handleDeletePax(passenger) {
      hidePopoverChangeQuantityPaxEvent.value = !hidePopoverChangeQuantityPaxEvent.value
      emit('deletePassenger', passenger)
    }

    function handleShowModalQuickInput() {
      hidePopoverChangeQuantityPaxEvent.value = !hidePopoverChangeQuantityPaxEvent.value
      this.$bvModal.show('id-popup-quick-input-multiple-pax')
    }

    provide('hidePopoverChangeQuantityPaxEvent', hidePopoverChangeQuantityPaxEvent)

    function addMultiplePassengers(paxs) {
      const {
        ADULT: adultsArray,
        CHILD: childrenArray,
        INFANT: infantsArray,
      } = paxs

      // const {
      //   adult: adultsCount,
      //   child: childrenCount,
      //   infant: infantsCount,
      // } = props.dataSearchFlight

      const adultsCount = isCreateGroupBooking.value ? paxDataGroupBooking.value.adult : dataSearchFlight.value.adult
      const childrenCount = isCreateGroupBooking.value ? paxDataGroupBooking.value.child : dataSearchFlight.value.child
      const infantsCount = isCreateGroupBooking.value ? paxDataGroupBooking.value.infant : dataSearchFlight.value.infant

      const addingAdultsCount = Math.min(adultsArray.length, adultsCount)
      const addingChildrenCount = Math.min(childrenArray.length, childrenCount)
      const addingInfantsCount = Math.min(infantsArray.length, infantsCount)

      if (addingAdultsCount) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < addingAdultsCount; index++) {
          const addingPax = adultsArray[index]
          const pax = props.passengerDataToAdd[index]
          pax.title = addingPax.title
          pax.firstName = addingPax.firstName
          pax.lastName = addingPax.lastName
          pax.fullName = addingPax.fullName
          pax.birthday = addingPax.birthday
          if (addingPax?.document) {
            pax.docTypeSelect = addingPax?.document?.type ?? ''
            pax.PaxDocuments[0].docType = addingPax?.document?.type ?? ''
            pax.PaxDocuments[0].number = addingPax?.document?.number ?? ''
            // let allCountries = store.getters['globalConfig/getAllCountries']
            // if (isEmpty(allCountries)) {
            //   await store.dispatch('globalConfig/fetchAllCountries')
            //   allCountries = store.getters['globalConfig/getAllCountries']
            // }
            // const findNationalityCountry = allCountries.find(c => c.shortCode === addingPax?.document?.nationality || c.longCode === addingPax?.document?.nationality)
            // pax.PaxDocuments[0].nationality = findNationalityCountry ?? ''
            // pax.PaxDocuments[0].endDate = addingPax?.document?.endDate ? convertISODateTime(addingPax?.document?.endDate)?.dateFilter : ''
            // const findIsCountry = allCountries.find(c => c.shortCode === addingPax?.document?.isCountry || c.longCode === addingPax?.document?.isCountry)
            // pax.PaxDocuments[0].isCountry = findIsCountry ?? ''
            // pax.PaxDocuments[0].gender = addingPax?.document?.gender ?? undefined
          }
        }
      }

      if (addingChildrenCount) {
        const startIndex = adultsCount
        const endIndex = startIndex + addingChildrenCount
        // eslint-disable-next-line no-plusplus
        for (let index = startIndex; index < endIndex; index++) {
          const childrenArrayIndex = index - startIndex
          const addingPax = childrenArray[childrenArrayIndex]
          const pax = props.passengerDataToAdd[index]
          pax.title = addingPax.title
          pax.firstName = addingPax.firstName
          pax.lastName = addingPax.lastName
          pax.birthday = addingPax.birthday
        }
      }

      if (addingInfantsCount) {
        const startIndex = adultsCount + childrenCount
        const endIndex = startIndex + addingInfantsCount
        // eslint-disable-next-line no-plusplus
        for (let index = startIndex; index < endIndex; index++) {
          const infantsArrayIndex = index - startIndex
          const addingPax = infantsArray[infantsArrayIndex]
          const pax = props.passengerDataToAdd[index]
          pax.title = addingPax.title
          pax.firstName = addingPax.firstName
          pax.lastName = addingPax.lastName
          pax.birthday = addingPax.birthday
        }
      }
    }

    const paxSuggestions = ref([])

    const getFilteredSuggestion = paxType => {
      if (!paxSuggestions.value || !paxSuggestions.value.length) return []

      if (!paxType) return paxSuggestions.value

      return [{ data: paxSuggestions.value[0].data.filter(pax => pax.type === paxType) }]
    }

    const paxFilteredSuggestions = computed(() => ({
      ADULT: getFilteredSuggestion('ADULT'),
      CHILD: getFilteredSuggestion('CHILD'),
      INFANT: getFilteredSuggestion('INFANT'),
    }))

    const fetchContactsPayload = {
      size: 10,
      page: 1,
      searchText: '',
    }
    let currentPaxIndex

    const setCurrentPaxIndex = currentIndex => {
      currentPaxIndex = currentIndex
    }

    const debounceSearchCustomerContact = debounce(searchText => {
      const customerId = props.customerId
      if (!customerId && employeeConfig.value?.showContactSuggestions) {
        const employeeId = store.getters['userStore/getMeDataId']
        if (!employeeId) return
        fetchContactsPayload.searchText = searchText
        apiEmployee.getEmployeeContacts(employeeId, fetchContactsPayload)
          .then(res => {
            paxSuggestions.value = [{ data: res.data.items.map(item => ({ ...item, firstName: item.firstName.toUpperCase(), lastName: item.lastName.toUpperCase() })) }]
          })
      }
      else if (customerId) {
        fetchContactsPayload.searchText = searchText
        apiCustomer.fetchContacts(customerId, fetchContactsPayload)
          .then(res => {
            paxSuggestions.value = [{ data: res.data.items.map(item => ({ ...item, firstName: item.firstName.toUpperCase(), lastName: item.lastName.toUpperCase() })) }]
          })
      }
    }, 300)

    const countryList = computed(() => store.getters['globalConfig/getAllCountries'])
    onMounted(() => {
      if (isEmpty(countryList.value)) {
        store.dispatch('globalConfig/fetchAllCountries')
      }
    })
    const sectionConfigs = ref({
      default: {
        onSelected: selected => {
          const selectedItem = selected.item
          const currentPax = props.passengerDataToAdd[currentPaxIndex]
          currentPax.firstName = formatterInput(selectedItem.firstName) || ''
          currentPax.lastName = formatterInput(selectedItem.lastName) || ''
          currentPax.fullName = formatterInput(`${selectedItem.lastName}${selectedItem.lastName.split(' ').length > 1 ? ' /' : ''} ${selectedItem.firstName}`) || ''
          // NOTE: 'birthday' !== 'birthDay'
          currentPax.birthday = selectedItem.birthDay ? selectedItem.birthDay.slice(0, 10) : ''
          currentPax.title = currentPax.title ? currentPax.title : selectedItem.gender || ''

          // NOTE: Nếu là VJ hoặc chuyến quốc tế thì fill CCCD/PP number
          if (!isDomesticTrips.value) {
            currentPax.docTypeSelect = 'P'
            currentPax.PaxDocuments[0].number = selectedItem?.passportNumber || ''
            currentPax.PaxDocuments[0].endDate = convertISODateTime(selectedItem?.passportConfig?.endDate)?.dateFilter || ''
            currentPax.PaxDocuments[0].nationality = countryList.value.find(country => country.longCode === selectedItem?.passportConfig?.nationality) ?? null
            currentPax.PaxDocuments[0].isCountry = countryList.value.find(country => country.longCode === selectedItem?.passportConfig?.placeOfIssue) ?? null
            currentPax.PaxDocuments[0].gender = selectedItem?.gender && ['MR', 'MSTR'].includes(selectedItem?.gender) ? 'MALE' : 'FEMALE'
          } else if (sources.value.includes('VJ')) {
            currentPax.docTypeSelect = 'C'
            currentPax.PaxDocuments[0].number = selectedItem?.identifyNumber || ''
          }

          if (selected.item?.owner && selected.item?.owner?.id !== props.customerId) {
            emit('updateCustomer', selected.item?.owner)
          }

          // auto fill member card: fill tất cả card của pax nếu có airline trong hành trình
          const filteredPaxCards = selectedItem.memberCards?.filter(memberCard => airlineLists.value.includes(memberCard?.supplierCode))
          if (!isEmpty(filteredPaxCards)) {
            filteredPaxCards.forEach((card, i) => {
              if (currentPax.memberFly[i]) {
                set(currentPax.memberFly[i], 'airline', card.supplierCode)
                set(currentPax.memberFly[i], 'cardNumber', card.cardNumber)
              } else {
                currentPax.memberFly.push({
                  airline: card.supplierCode,
                  cardNumber: card.cardNumber,
                })
              }
            })
          }
        },
      },
    })

    const highlightSearchString = (fullName, searchString) => {
      if (!searchString) return fullName

      const regex = new RegExp(searchString, 'gi')
      const highlightedName = fullName.replace(regex, match => `<span class="text-danger">${match}</span>`)

      return highlightedName
    }

    function getSuggestionValue(suggestion, itemKey) {
      if (itemKey === 'fullName') {
        return `${suggestion.item.lastName} ${suggestion.item.firstName}`
      }
      return suggestion.item[itemKey]
    }

    watch(
      () => props.customerId,
      value => {
        if (value) {
          debounceSearchCustomerContact('')
        }
      },
    )

    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: '1.1.1920',
        maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    const configFlatPickrPassport = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    const getMaxLengthPax = type => {
      if (isCreateGroupBooking.value) {
        return paxDataGroupBooking.value[`${type}Modified`]
      }
      return dataSearchFlight.value[type]
    }

    const isDisabledSelectParentPaxId = computed(() => selectedTrip.value.some(trip => trip?.source === '1G'))

    const resolveHeaderNamePassenger = (p, index) => {
      if (p.title && p.firstName && p.lastName) {
        return `${p.lastName} ${p.firstName} ${p.title}`
      }
      return `${p.paxType} #${index + 1}`
    }

    const enableQuickName = computed(() => store.getters['userStore/getEnableQuickName'])

    function fakePaxInfo() {
      props.passengerDataToAdd.forEach(e => {
        const sex = ['male', 'female'][Math.floor(Math.random() * 2)]
        e.firstName = formatterInput(fakerVI.person.firstName(sex).toUpperCase())
        e.lastName = formatterInput(fakerVI.person.lastName(sex).toUpperCase())
        e.fullName = `${e.lastName} ${e.firstName}`
        if (e.paxType === 'ADULT') {
          e.title = sex === 'male' ? 'MR' : ['MRS', 'MS'][Math.floor(Math.random() * 2)]
          e.birthday = fakerVI.date.birthdate({
            min: 12, max: 60, refDate: new Date(), mode: 'age',
          })
        } else if (e.paxType === 'CHILD') {
          e.title = sex === 'male' ? 'MSTR' : 'MISS'
          e.birthday = fakerVI.date.birthdate({
            min: 2, max: 11, refDate: new Date(), mode: 'age',
          })
        } else {
          e.title = sex === 'male' ? 'MSTR' : 'MISS'
          e.birthday = fakerVI.date.birthdate({
            min: 0, max: 1, refDate: new Date(), mode: 'age',
          })
        }
        if ('PaxDocuments' in e && !['INFANT'].includes(e.paxType)) {
          const vn = {
            id: 192,
            countryName: 'Vietnam',
            shortCode: 'VN',
            longCode: 'VNM',
          }

          e.PaxDocuments[0] = {
            ...e.PaxDocuments[0],
            gender: sex.toUpperCase(),
            isCountry: vn,
            nationality: vn,
            paxId: e.paxId,
            endDate: fakerVI.date.future({ years: 5, refDate: new Date() }),
            number: `B${fakerVI.number.int({ min: 1234567, max: 9999999 })}`,
          }
        }
      })
    }

    const flightMaxDate = computed(() => {
      const allDepartureDate = selectedTrip.value?.flat().map(item => item.departure.at)
      return getMaxTime(allDepartureDate)
    })

    function onClickQrScanHandle(idModal) {
      this.$bvModal.show(idModal)
    }

    // function hasDiscountBSVTripVN1A(trips) {
    //   if (isEmpty(trips)) return false
    //   const listVN1ATrips = trips.filter(trip => trip.source === 'VN1A')
    //   if (isEmpty(listVN1ATrips)) return false
    //   const segmentList = listVN1ATrips.flatMap(({ segments }) => segments.map(s => ({ departure: s.departure.IATACode, arrival: s.arrival.IATACode })))
    //   const bookingClassList = listVN1ATrips.flatMap(({ fareOptions }) => fareOptions.bookingClass.split(' | '))
    //   const { type: bookingType } = dataSearchFlight.value || {}
    //   return checkDiscountVN1A(segmentList, bookingClassList, bookingType, isDomesticTrips.value)
    // }

    function splitFullNameHandle(pax) {
      pax.fullName = pax?.fullName?.trim()
      const splitBy = pax?.fullName?.includes('/') ? '/' : ' '
      pax.lastName = pax?.fullName?.split(splitBy)
        .shift().trim()
        .replaceAll(/\s+/g, ' ')
      pax.firstName = pax?.fullName?.split(splitBy)
        .slice(1).join(' ').trim()
        .replaceAll(/\s+/g, ' ')
    }

    return {
      titleOptions,
      refFormObserver,
      getValidationState,
      getValidateBirthday,
      getValidatePassport,
      configFlatPickr,
      configFlatPickrPassport,
      // Validations
      required,
      min,
      max,
      email,
      isUnique,
      getMaxTime,
      flightMaxDate,
      isLastFlightDateAfterPassportExpiry,
      duplicateParentId,
      alphaSpaces,
      alphaNum,
      formatterInput,
      trimAllInputAndUpper,
      formatterCardNumber,
      Vietnamese,
      isEmpty,
      SOURCE_DISABLE_ADD_MORE_PAXS,

      parentOptions,
      parentSelected,
      handleOpenModalRegisterMembershipCard,
      indexMemberFly,
      memberFlyData,
      paxDataSelect,
      addMultiplePassengers,
      getMaxLengthPax,

      getLocaleFullName,
      paxFilteredSuggestions,
      setCurrentPaxIndex,
      debounceSearchCustomerContact,
      sectionConfigs,
      highlightSearchString,
      getSuggestionValue,
      handleDeletePax,
      handleShowModalQuickInput,

      isDomesticTrips,

      getAirlineOptionsGroupByTrips,
      isDisabledSelectParentPaxId,
      genderOptions,

      resolveHeaderNamePassenger,
      enableQuickName,
      isDevelopment,
      fakePaxInfo,
      suggestVisible,
      onSetVisible,
      minWord,

      isHideFunction,
      mamaShortName,
      isCombineFullName,
      isDobInputOutsideDetailBox,
      isEnableMemberShipCardAndPassport,
      isEnableFormPassport,
      checkPassportLessThan6MonthsBeforeFlightDate,
      convertISODateTime,
      onClickQrScanHandle,
      airlineLists,
      splitFullNameHandle,
      // hasDiscountBSVTripVN1A,
      segmentList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.border-transparent {
  border-color: transparent !important;
  background-color: transparent !important;
}

.tooltip-info-class > .tooltip-inner {
  text-align: justify;
  font-size: 14px;
  max-width: 400px;
  border: 1px solid black !important;
  background-color: white !important;
}
</style>

<style lang="scss" scoped>
.membership-airline-select ::v-deep {
  background-color: white;

  .vs__dropdown-toggle {
    min-height: 37px !important;
    border: 0px !important;
    padding-bottom: 0 !important;

    .vs__open-indicator {
      margin-top: 0 !important;
    }
  }

  ul.vs__dropdown-menu {
    min-width: 300px !important;
  }
}

.select-size-sm ::v-deep {
  .vs__selected {
    font-size: 1.1rem !important;
  }
}

.honorific_select {
  background-color: white;

  ::v-deep .vs__dropdown-toggle {
    min-height: 37px !important;
    padding-bottom: 2px !important;

    .vs__open-indicator {
      margin-top: 0 !important;
    }

    .vs__search {
      padding: 0px !important;
    }
  }

  .font_birthday_passenger {
    font-size: 1.2rem !important; // medium-2

    @media (max-width: 768px) {
      font-size: 0.8rem !important; // small-2
    }
  }
}

.border-danger-select ::v-deep {
  .vs__dropdown-toggle {
    border-color: red
  }
}

.custom-last-name-autosuggest ::v-deep {
  .autosuggest__results-container {
    width: 300% !important;
    right: 50% !important;
  }
}
.custom-last-name-autosuggest-desktop ::v-deep {
  .autosuggest__results-container {
    width: 150% !important;
  }
}
.custom-first-name-autosuggest ::v-deep {
  .autosuggest__results-container {
    width: 200% !important;
    right: 100% !important;
  }
}

.passport_class {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    padding-right: .5rem;
    margin-top: .5rem;
  }
}
</style>
